import React from 'react';
import { Grid, Box, Typography } from '@mui/material';
import breakfastImg from './Breakfast.webp'; // Import your images
import useWindowSize from './WindowSize';
import { Image } from 'mui-image'

const Breakfast = () => {
  const { width, height } = useWindowSize();
  const isMobile = width < 600; // Adjust the breakpoint as needed
  return (
   <Box style={{position:'inherit'}}>
    <Typography align='center' variant='h2' color={'#5DB3E6'}>Frukost</Typography>   
    <Grid
      container
      sx={{
        height: '100vh',
        display: 'flex',
        flexDirection: { xs: 'column', lg: 'row' },
      }}
    >
        {/* Left half of the page on laptops with video background */}
        <Box
        sx={{
          flex: 1,
          height: '100vh',
          position: 'relative',
          overflow: 'hidden',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Image src={breakfastImg} style={{
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            position: 'absolute',
            zIndex: 0,
          }}/>
      </Box>

      {/* Right half of the page on laptops */}
      <Box
        sx={{
          flex: 1,
          height: '100vh',
          width:'100vw',
          position: 'relative',
          /*backgroundImage: `url(${bgImage1})`,*/
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
        
        }}
      >
        
        {/* Text with background */}
        <Box
          sx={{
            position: 'absolute',
            backgroundColor: 'white',
            color: 'black',            
            textAlign: 'center',
            width: '100%',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex:'1',
          }}
        >
          {isMobile ? (
            <div style={{position:'relative', margin:'1vw'}}>
              
              <Typography fontSize="4vw">Vår omtyckta frukostbuffé serveras kl 7.30-9.30 under sommaren.</Typography>
              <Typography fontSize="4vw">Självklart går det bra att besöka vår frukost även om du inte bor på hotellet, priset är då 140 kr/person. 80 kr/barn upp till 12 år. Ring 070 44 33 299 för att boka.</Typography>
              <Typography fontSize="4vw">Lågsäsong: När vi har färre än 4 rum uthyrda finns vår frukost till självservering, där du tar för dig själv när det passar dig.</Typography>
            </div>
          ) : (
            <div style={{position:'relative', margin:'1vh'}}>
              
              <Typography fontSize="2.5vh">Vår omtyckta frukostbuffé serveras kl 7.30-9.30 under sommaren.</Typography>  
              <Typography fontSize="2.5vh">Självklart går det bra att besöka vår frukost även om du inte bor på hotellet, priset är då 140 kr/person. 80 kr/barn upp till 12 år. Ring 070 44 33 299 för att boka.</Typography>
              <Typography fontSize="2.5vh">Lågsäsong: När vi har färre än 4 rum uthyrda finns vår frukost till självservering, där du tar för dig själv när det passar dig.</Typography>
            </div>
          )}
        </Box>
      </Box>
    </Grid>
    </Box>

  );
};

export default Breakfast;