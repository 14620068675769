import React from 'react';
import Button from '@mui/material/Button';

const CustomButton = ({ href, width, height, label, bgColor, textColor, border, bgImage }) => {
  const handleButtonClick = () => {
    // Handle the click event, e.g., navigate to the desired URL
    window.location.href = href;
  };

  return (
    <Button
      style={{
        width: width || '20vw',
        height: height || '5vh',
        backgroundColor: 'white',
        border: border || '2px solid gray',
        color: 'black',
        backgroundColor: bgColor || 'transparent',
        color: textColor || 'black',
        margin:'2px',
        backgroundImage: bgImage ? `url(${bgImage})` : 'none',
        backgroundSize: '100% 100%', 
      }}
      onClick={handleButtonClick}
      target="_blank"
    >
      <b>{label}</b>
    </Button>
  );
};

export default CustomButton;
