import React, { useState } from 'react'
import{AppBar, Box, Grid, Tab, Toolbar, Typography, useTheme, useMediaQuery} from '@mui/material'
import Tabs from '@mui/material/Tabs';
import DrawerComp from './DrawerComp';
import CustomButton from './CustomButton';
import englishFlag from './english.png';
import germanFlag from './german.png'
import logo from './SliteBadhotell.png'

const Navbar = ({menuItems}) => {
    const theme = useTheme();
    console.log(theme);
    const isMatch = useMediaQuery(theme.breakpoints.down('md'));
    console.log(isMatch);
    const [value, setValue] = useState();
    return(
        
        <AppBar sx={{background: 'none'}}>
            <Toolbar sx={{background: 'rgb(9 159 190/0.8)'}} >
               {isMatch? <>
                <Typography>
                    <strong>Slite</strong><br/><strong>Badhotell</strong>
                </Typography>
                <picture><img src={logo} style={{width:'25vw',filter:'invert(99%) sepia(1%) saturate(1365%) hue-rotate(128deg) brightness(118%) contrast(101%)'}}/></picture>
                
                <DrawerComp links={menuItems}/>
               </> :
                <Grid sx={{placeItems:'center', }} container>
                 
                    <Grid item xs={2}>
                     <Typography sx={{fontSize: '1.2vw'}}>                               
                        <strong>Slite</strong><br/><strong>Badhotell</strong>
                    </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Tabs indicatorColor='secondary' textColor='white' value={value} onChange={(e,val)=>setValue(val)}>
                            {menuItems.map((link, index) => (
                                <Tab key={index} label={<strong>{link}</strong>} sx={{textTransform: 'initial', fontSize:'2.5vh', color:'White', opacity:'1' }} href={`#${link}`}/>
                            ))}
                        </Tabs>
                        
                    </Grid>
                    <Grid item xs={1}/>
                    
                    <Grid item xs={3}>
                        <Box display="flex">
                            <CustomButton href="https://5c308415eb5ce.sirvoy.me/en?language=en" width="4vw" height="5vh" bgImage={englishFlag} textColor='white' target="_blank" rel="noopener"/> 
                            <CustomButton href="https://5c308415eb5ce.sirvoy.me/de?language=de" width="4vw" height="5vh" bgImage={germanFlag} textColor='white'  target="_blank" rel="noopener"/>
                        </Box>
                    </Grid>
                </Grid>
                }
            </Toolbar>
        </AppBar>
        
        
    )
}

export default Navbar