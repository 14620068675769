import React from 'react';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Typography from '@mui/material/Typography';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Button } from '@mui/base';
import FacilityIcons from './GenerateIcons';
import CustomButton from './CustomButton';
import enkel1 from "./imagesRoom/Enkel-1/01.webp";
import enkel2 from "./imagesRoom/Enkel-1/02.webp";
import enkel3 from "./imagesRoom/Enkel-1/03.webp";
import enkel4 from "./imagesRoom/Enkel-1/04.webp";
import enkel5 from "./imagesRoom/Enkel-1/05.webp";
import enkel6 from "./imagesRoom/Enkel-1/06.webp";
import enkel7 from "./imagesRoom/Enkel-1/07.webp";
import enkel8 from "./imagesRoom/Enkel-1/08.webp";
import enkel9 from "./imagesRoom/Enkel-1/09.webp";
import enkel10 from "./imagesRoom/Enkel-1/10.webp"; 

import dubbel1 from "./imagesRoom/Dubbel-1/01.webp";
import dubbel2 from "./imagesRoom/Dubbel-1/02.webp";
import dubbel3 from "./imagesRoom/Dubbel-1/03.webp";
import dubbel4 from "./imagesRoom/Dubbel-1/04.webp";
import dubbel5 from "./imagesRoom/Dubbel-1/05.webp";
import dubbel6 from "./imagesRoom/Dubbel-1/06.webp";

import kompact1 from "./imagesRoom/Kompact_2/01.webp";
import kompact2 from "./imagesRoom/Kompact_2/02.webp";
import kompact3 from "./imagesRoom/Kompact_2/03.webp";
import kompact4 from "./imagesRoom/Kompact_2/04.webp";
import kompact5 from "./imagesRoom/Kompact_2/05.webp";
import kompact6 from "./imagesRoom/Kompact_2/06.webp";
import kompact7 from "./imagesRoom/Kompact_2/07.webp";

import kompactDouble1 from "./imagesRoom/Kompact_Double/01.webp";
import kompactDouble2 from "./imagesRoom/Kompact_Double/02.webp";
import kompactDouble3 from "./imagesRoom/Kompact_Double/03.webp";

import svit1 from "./imagesRoom/Svit/01.webp";
import svit2 from "./imagesRoom/Svit/02.webp";
import svit3 from "./imagesRoom/Svit/03.webp";
import svit4 from "./imagesRoom/Svit/04.webp";
import svit5 from "./imagesRoom/Svit/05.webp";
import svit6 from "./imagesRoom/Svit/06.webp";

import fyrbadds1 from "./imagesRoom/Fyrbadds/01.webp";
import fyrbadds2 from "./imagesRoom/Fyrbadds/02.webp";
import fyrbadds3 from "./imagesRoom/Fyrbadds/03.webp";
import fyrbadds4 from "./imagesRoom/Fyrbadds/04.webp";
import fyrbadds5 from "./imagesRoom/Fyrbadds/05.webp";
import fyrbadds6 from "./imagesRoom/Fyrbadds/06.webp";
import fyrbadds7 from "./imagesRoom/Fyrbadds/07.webp";

import familjerum1 from "./imagesRoom/Familjerum/01.webp";
import familjerum2 from "./imagesRoom/Familjerum/02.webp";
import familjerum3 from "./imagesRoom/Familjerum/03.webp";
import familjerum4 from "./imagesRoom/Familjerum/04.webp";
import familjerum5 from "./imagesRoom/Familjerum/05.webp";
import familjerum6 from "./imagesRoom/Familjerum/06.webp";
import familjerum7 from "./imagesRoom/Familjerum/07.webp";
import familjerum8 from "./imagesRoom/Familjerum/08.webp";

import ingmar1 from "./imagesRoom/Ingmar/01.webp";
import ingmar2 from "./imagesRoom/Ingmar/02.webp";
import ingmar3 from "./imagesRoom/Ingmar/03.webp";
import ingmar4 from "./imagesRoom/Ingmar/04.webp";
import ingmar5 from "./imagesRoom/Ingmar/05.webp";

import raukrum1 from "./imagesRoom/Raukrum/01.webp";
import raukrum2 from "./imagesRoom/Raukrum/02.webp";
import raukrum3 from "./imagesRoom/Raukrum/03.webp";
import raukrum4 from "./imagesRoom/Raukrum/04.webp";

const roomsData = [
  {
    title: 'Enkelrum med privat badrum',
    subheader: '1 Gäst, 11-14 m2',
    images: [
      enkel1,
      enkel2,
      enkel3,
      enkel4,
      enkel5,
      enkel6,
      enkel7,
      enkel8,
      enkel9,
      enkel10,           
    ],
    facilities:{
      singlebed:1,
      breakfast:1,
      shower:1,
      toilet:1,
      wifi:1,
      tv:1
    },
    content:
      'Rum perfekta för den som vill ha ett eget rum med ett privat badrum. Vi har bäddat med härligt manglat linne och lagt in handdukar. Varje rum har en TV, bra Wifi, vattenkokare, dricksglas, sängbord, läslampa, krokar och galgar för kläderna. De flesta rummen har en arbetsyta med stol. I badrummet med dusch finns badrumsmatta, handtvål, schampo, duschkräm och hårfön. För att värna om Er integritet och säkerhet går vi inte in i rummet under Er vistelse.',
  }, 
  {
    title: 'Dubbelrum med privat badrum',
    subheader: '2 Gäster, 14-20 m2',
    images: [
      dubbel1,
      dubbel2,
      dubbel3,
      dubbel4,
      dubbel5,
      dubbel6,
    ],
    facilities:{
      doublebed:1,
      breakfast:1,
      shower:1,
      toilet:1,
      wifi:1,
      tv:1
    },
    content: 'Rum perfekta för två personer som delar en dubbelsäng. Vi har bäddat med härligt manglat linne och lagt in handdukar. Varje rum har en TV, bra Wifi, vattenkokare, dricksglas, sängbord, läslampor, krokar och galgar för kläderna. De flesta rummen har en arbetsyta med stol. I badrummet med dusch finns badrumsmatta, handtvål, schampo, duschkräm och hårfön. För att värna om Er integritet och säkerhet går vi inte in i rummet under Er vistelse.',
  },
  {
    title: 'Kompakt tvåbäddsrum med privat badrum',
    subheader: '2 Gäster, 11-14 m2',
    images: [
      kompact1,
      kompact2,
      kompact3,
      kompact4,
      kompact5,
      kompact6,
      kompact7,
    ],
    facilities:{
      singlebed:2,
      breakfast:1,
      shower:1,
      toilet:1,
      wifi:1,
      tv:1
    },
    content: 'Rum möblerade med två separata sängar. Vi har bäddat med härligt manglat linne och lagt in handdukar. Varje rum har en TV, bra Wifi, vattenkokare, dricksglas, sängbord, läslampor, krokar och galgar för kläderna. Dessa rummen är trånga och har inga garderober eller stolar. I badrummet med dusch finns badrumsmatta, handtvål, schampo, duschkräm och hårfön. För att värna om Er integritet och säkerhet går vi inte in i rummet under Er vistelse.',
  },
  {
    title: 'Kompakt dubbelrum med privat badrum',
    subheader: '2 Gäster, 11-14 m2',
    images: [
      kompactDouble1,
      kompactDouble2,
      kompactDouble3,
    ],
    facilities:{
      doublebed:1,
      breakfast:1,
      shower:1,
      toilet:1,
      wifi:1,
      tv:1
    },
    content: 'Rum möblerade med en dubbesäng 160 cm. Vi har bäddat med härligt manglat linne och lagt in handdukar. Varje rum har en TV, bra Wifi, vattenkokare, dricksglas, sängbord, läslampor, krokar och galgar för kläderna. Dessa rummen är trånga och har inga garderober eller stolar. I nyrenoverade badrummet med dusch finns badrumsmatta, handtvål, schampo, duschkräm och hårfön. För att värna om Er integritet och säkerhet går vi inte in i rummet under Er vistelse.',
  },
  {
    title: 'Svit med två rum och egen ingång',
    subheader: '4 Gäster, 25 m2',
    images: [
      svit1,
      svit2,
      svit3,
      svit4,
      svit5,
      svit6,
    ],
    facilities:{
      bunkbed:1,
      doublebed:1,
      breakfast:1,
      shower:1,
      toilet:1,
      wifi:1,
      tv:1
    },
    content: 'Privat svit med två separata rum och egen ingång från trädgården utan trappor. Nyrenoverat. I stora rummet finns en dubbelsäng och sittgrupp. I lilla rummet finns en våningsäng och en skjudörr. Vi har bäddat med härligt manglat linne och lagt in handdukar. Stora rummet har en TV, bra Wifi, vattenkokare, dricksglas, sängbord, läslampor, garderob, krokar och galgar för kläderna. I badrummet med dusch finns badrumsmatta, handtvål, schampo, duschkräm och hårfön. För att värna om Er integritet och säkerhet går vi inte in i rummet under Er vistelse.',
  },
  {
    title: 'Fyrbäddsrum med privat badrum',
    subheader: '4 Gäster, 25 m2',
    images: [
      fyrbadds1,
      fyrbadds2,
      fyrbadds3,
      fyrbadds4,
      fyrbadds5,
      fyrbadds6,
      fyrbadds7,
    ],
    facilities:{
      doublebed:1,
      singlebed:2,
      breakfast:1,
      shower:1,
      toilet:1,
      wifi:1,
      tv:1
    },
    content: 'Tidigare två rum som slagits ihop till ett stort rum. En dubbelsäng 180 cm och två enkelsängar 80 cm. Har slipper du klättra upp i en våningsäng. Vi har bäddat med härligt manglat linne och lagt in handdukar. I rummet finns en TV, bra Wifi, vattenkokare, dricksglas, sängbord, läslampor, krokar och galgar för kläderna. Här finns också en sittplats. I badrummet med dusch finns badrumsmatta, handtvål, schampo, duschkräm och hårfön. För att värna om Er integritet och säkerhet går vi inte in i rummet under Er vistelse.',
  },
   {
    title: 'Familjerum med privat badrum',
    subheader: '4 Gäster, 18 m2',
    images: [
      familjerum1,
      familjerum2,
      familjerum3,
      familjerum4,
      familjerum5,
      familjerum6,
      familjerum7,
      familjerum8,
    ],
    facilities:{
      bunkbed:1,
      doublebed:1,
      breakfast:1,
      shower:1,
      toilet:1,
      wifi:1,
      tv:1
    },
    content: 'Mysiga familjerum med en dubbelsäng 180 cm en våningssäng 90 cm med privat badrum. Vi har bäddat med härligt manglat linne och lagt in handdukar. I rummet finns en TV, bra Wifi, vattenkokare, dricksglas, sängbord, läslampor, krokar och galgar för kläderna samt en garderob. I badrummet med dusch finns badrumsmatta, handtvål, schampo, duschkräm och hårfön. För att värna om Er integritet och säkerhet går vi inte in i rummet under Er vistelse.',
  },
 {
    title: 'Stora sviten med privat badrum',
    subheader: '4 Gäster, 28 m2',
    images: [
      ingmar1,
      ingmar2,
      ingmar3,
      ingmar4,
      ingmar5,
    ],
    facilities:{
      doublebed:2,
      breakfast:1,
      shower:1,
      toilet:1,
      wifi:1,
      tv:1
    },
    content: 'Vårt största och finaste rum med stora fönster mot parken och stort privat badrum. Rummet har en dubbelsäng 180 cm och även en bäddsoffa som 2 personer kan sova i. Vi har bäddat med härligt manglat linne och lagt in handdukar. I rummet finns en TV, bra Wifi, vattenkokare, dricksglas, sängbord, läslampor, krokar och galgar för kläderna samt en garderob. I det stora badrummet med dusch finns badrumsmatta, handtvål, schampo, duschkräm och hårfön. För att värna om Er integritet och säkerhet går vi inte in i rummet under Er vistelse.',
  },
 {
    title: 'Tvåbäddsrum med extern wc och dusch',
    subheader: '2 Gäster, 8-9 m2',
    images: [
      raukrum1,
      raukrum2,
      raukrum3,
      raukrum4,
    ],
    facilities:{
      singlebed:2,
      breakfast:1,
      shower:1,
      toilet:1,
      wifi:1,
      tv:1
    },
    content: 'Rum med högt i tak och med stora fönster ut mot parken där sekelskiftescharmen skymtar. Rummen är något lyhörda. Rummet har en privat nyrenoverad toalett och dusch men dessa ligger dock på en annan våning och ej i rummet. Vi har bäddat med härligt manglat linne och lagt in handdukar. I rummet bra Wifi, vattenkokare, dricksglas, sängbord, läslampor, krokar och galgar för kläderna. I det det externa badrummet med dusch finns badrumsmatta, handtvål, schampo, duschkräm och hårfön. För att värna om Er integritet och säkerhet går vi inte in i rummet under Er vistelse.', 
  },
];

const RoomCard = ({room}) => {

   /*const [expanded, setExpanded] = React.useState(false);
    const handleExpandClick = () => {
    setExpanded(!expanded);
    }; */

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column', margin: 2, spacing:2 }}>
      <CardHeader
        titleTypographyProps={{ variant: 'h8', fontWeight: 'bold', align:'left' }}  // Adjust title styles
        subheaderTypographyProps={{ variant: 'body2', color: 'text.secondary', align:'left' }}  // Adjust subheader styles    
        
       /* action={
          <IconButton aria-label="settings">
            
          </IconButton>
        } */
        title={room.title}
        subheader= {room.subheader}
      />
      <Slider {...settings}>
        {room.images.map((image, index) => (
          //<CardMedia key={index} component="img" height="194" image={image} alt={room.title} />
          <CardMedia key={index} component="img" height="194" src={process.env.PUBLIC_URL+image} alt={room.title} />
          
        ))}
      </Slider>
      <CardContent sx={{ flex: 1 }}>
        <Typography variant="body2" color="text.secondary">
          {room.content}      
        </Typography>
      </CardContent>
      

       <CardActions disableSpacing>
       <FacilityIcons facilities={room.facilities} />
       </CardActions>
       {/*
       <IconButton
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
        >
          <Icon path={mdiChevronDown} size={1} />
        </IconButton>
        </CardActions>
          <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <Typography paragraph>{room.method}</Typography>
        </CardContent>
        </Collapse> 
        */}

      <CardContent style={{marginBottom:'2%'}}>      
      <CustomButton href="#Boka" width="100%" height="5vh" label={"BOKA"}/>
      </CardContent>      

    </Card>
  );
};

const RoomGallery = ({ rooms = roomsData }) => {
  return (
      
    <Grid container spacing={1} justifyContent="center" overflow={'scroll'} height={'auto'}>
      {rooms.map((room, index) => (
        <Grid key={index} item xs={12} sm={6} md={4} lg={3}>
          <RoomCard room={room} />
        </Grid>
      ))}
    </Grid>
  );
};

export default RoomGallery;
