import React from 'react';
import { Typography } from '@mui/material';


const FaqAnswers = ({ answers }) => {
  const generateFaq = () => {
    const answersToDisplay = [];

    for (const [answerTitle, answerText] of Object.entries(answers)) {
      
      answersToDisplay.push(
        <Typography><b>{answerTitle}</b><br/>{answerText}</Typography>
      )        
      
    }

    return answersToDisplay;
  };
  return (
    <div>
      {generateFaq()}
    </div>
  );
};
export default FaqAnswers;