import React, { useState } from 'react'
import {Drawer, IconButton, List, ListItemButton, ListItemIcon, ListItemText} from '@mui/material'
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import CustomButton from './CustomButton';
import englishFlag from './english.png';
import germanFlag from './german.png';
const DrawerComp = ({links}) => {
    const [open, setOpen] = useState(false)
  return (
    <>
      <Drawer PaperProps={{
        sx:{backgroundColor:'rgba(9,159,190,1)'}
      }} open={open} onClose={()=>setOpen(false)}>
        <List>
           {links.map((link,index)=>(
            <ListItemButton href={`#${link}`} onClick={()=>setOpen(false)} key={index} divider>
                <ListItemIcon>
                    <ListItemText sx={{color:'white'}}>
                        {link}
                    </ListItemText>
                </ListItemIcon>
            </ListItemButton>
                    
           ))}
                    <ListItemButton>
              <CustomButton href="https://slitebadhotell-se.translate.goog/?_x_tr_sl=sv&_x_tr_tl=en&_x_tr_hl=en&_x_tr_pto=wapp" width="4vw" height="5vh" bgImage={englishFlag} textColor='white'/> 
            </ListItemButton>
            <ListItemButton>
              <CustomButton href="https://slitebadhotell-se.translate.goog/?_x_tr_sl=sv&_x_tr_tl=de&_x_tr_hl=en&_x_tr_pto=wapp" width="4vw" height="5vh" bgImage={germanFlag} textColor='white'/>
            </ListItemButton>
        </List>
      </Drawer>
      <IconButton sx={{marginLeft:'auto', color:'white'}} onClick={()=>setOpen(!open)}>
        <MenuRoundedIcon/>
      </IconButton>
    </>
  )
}
export default DrawerComp