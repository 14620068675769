import React from 'react';
import { Grid, Box, Button, Typography } from '@mui/material';
import bgImage1 from './bgImage1.webp'; // Import your images
import video2 from './video2.mp4';
import Divider from '@mui/material-next/Divider';
import useWindowSize from './WindowSize';
import * as styles from './ImageGallery.css';

import image1 from './ImageGalleryPics/image1.webp';
import image2 from './ImageGalleryPics/image2.webp';
import image3 from './ImageGalleryPics/image3.webp';
import image4 from './ImageGalleryPics/image4.webp';
import image5 from './ImageGalleryPics/image5.webp';
import image6 from './ImageGalleryPics/image6.webp';
import image7 from './ImageGalleryPics/image7.webp';
import image8 from './ImageGalleryPics/image8.webp';

const { useState } = React;

//IMAGES
//you can also import a local file, the syntax would look like:
//import image1 from './images/imagename.jpg'


//IMAGE ARRAY
const images = [image1, image2, image3, image4, image5, image6, image7, image8];


//MAIN LIGHTBOX
//Holds Images Cards and Lightbox
//this is where all of our logic will live
function ImageGallery() {
  const [imageToShow, setImageToShow] = useState("");
  const [lightboxDisplay, setLightBoxDisplay] = useState(false);
  
  //looping through our images array to create img elements
  const imageCards = images.map((image) => (
    <img className="image-card" onClick={() => showImage(image)} src={image} />
  ));

  //function to show a specific image in the lightbox, amd make lightbox visible
  const showImage = (image) => {
    setImageToShow(image);
    setLightBoxDisplay(true);
  };

  //hide lightbox
  const hideLightBox = () => {
    setLightBoxDisplay(false);
  };

  //show next image in lightbox
  const showNext = (e) => {
    e.stopPropagation();
    let currentIndex = images.indexOf(imageToShow);
    if (currentIndex >= images.length - 1) {
      setLightBoxDisplay(false);
    } else {
      let nextImage = images[currentIndex + 1];
      setImageToShow(nextImage);
    }
  };

  //show previous image in lightbox
  const showPrev = (e) => {
    e.stopPropagation();
    let currentIndex = images.indexOf(imageToShow);
    if (currentIndex <= 0) {
      setLightBoxDisplay(false);
    } else {
      let nextImage = images[currentIndex - 1];
      setImageToShow(nextImage);
    }
  };
  

  return (
    <>
      <Typography align='center' variant='h2' color={'#5DB3E6'}>Gemensamma Utrymmen och Slite</Typography>
      <div style={{ marginRight: '5vw', marginLeft: '5vw' }}>{imageCards}</div>
      
      {
        lightboxDisplay ? 
        <div id="lightbox" onClick={hideLightBox}>
          <button onClick={showPrev}>⭠</button>
          <img id="lightbox-img" src={imageToShow} alt="hotell gemensamma utrymmen, slite stand, tennis, paddel och pingis i slite"></img>
          <button onClick={showNext}>⭢</button>
        </div>
       : ""
      }
    </>
  );
}

export default ImageGallery;