import './App.css';
import { Grid, Paper, Typography } from '@mui/material';
import hotelBuilding from './pics/bgImage1.jpg';
import React from 'react';
import Navbar from './components/Navbar';
import { Box } from '@mui/system';
import SirvoyBooking from './components/SirvoyBooking';
import HomePage from './components/Homepage';
import FAQPage from './components/Faq';
import Breakfast from './components/Breakfast';
import RoomGallery from './components/RoomGallery';
import StandardImageList from './components/ImageGallery';
import Footer from './components/Footer';

const menuItemsArray = ["Hem","Rum","Frukost","Hotellet","Boka"];
const mainStyles = {
  firstSection: {
    minHeight: 'fit-content',
    maxHeight: 'fit-content', // Set the height for the first section
    backgroundImage:`url(${hotelBuilding})`,
    backgroundSize:'cover', 
    backgroundRepeat:'no-repeat',
    backgroundPosition: 'center',
    justifyContent:"center",
    alignItems:"center",
  },
  otherSections: {
    padding: '2',
    minHeight:'fit-content',
    
  },
  
};
function App() {
  return (
    <Box display="flex" flexDirection="column">
      <Grid container spacing={0} component="main"  justifyContent="center" alignItems="center" sx={{ flex: 1 }}>
      {/* First Section with fixed height */}

      <Grid item xs={12} style={{position:'relative'}}>        
          <Navbar menuItems={menuItemsArray}/> 
      </Grid>

      <Grid  item xs={12} style={{position:'relative'}} id='Hem'> 
      <HomePage/>      
      </Grid>

      <Grid item xs={12} id='Rum'>       
        <Paper style={mainStyles.otherSections} height={'fit-content'}>
          <Typography align='center' variant='h2' color={'#5DB3E6'}>Våra Hotellrum</Typography>
          <RoomGallery/>
        </Paper>
      </Grid>

      <Grid item xs={12} style={{position:'relative'}} id='Frukost'> 
      <Paper style={mainStyles.otherSections} minHeight={'fit-content'}>
      <Breakfast/>
      </Paper>      
      </Grid>

      <Grid  item xs={12} style={{position:'relative'}} id='Hotellet'> 
      <Paper style={mainStyles.otherSections} minHeight={'fit-content'}>
      <StandardImageList/>
      </Paper>      
      </Grid>

      <Grid item xs={12} id='Faq'>
        <Paper style={mainStyles.otherSections}>
          {/* Content for other sections */}
          <FAQPage/>
        </Paper>
      </Grid>
      
      <Grid item xs={12} id='Boka'>
      <SirvoyBooking/>
      </Grid>
     </Grid>
     
       
        <Footer/>

      
    </Box>
  );
}

export default App;