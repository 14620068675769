import React from 'react';

import Icon from '@mdi/react';
import { mdiBunkBed, mdiBedKing, mdiBedSingle, mdiWifi, mdiCoffee, mdiShowerHead, mdiToilet, mdiTelevision } from '@mdi/js';


const BedIcons = ({ facilities }) => {
  const generateIcons = () => {
    const icons = [];

    for (const [facilityType, count] of Object.entries(facilities)) {
      for (let i = 0; i < count; i++) {
        // Replace the placeholder with Material-UI icons
        switch (facilityType) {
          case 'singlebed':
            icons.push(<Icon path={mdiBedSingle} size={1.1} key={`${facilityType}-${i}`}/>);
            break;
          case 'doublebed':
            icons.push(<Icon path={mdiBedKing} size={1.1} key={`${facilityType}-${i}`} />);
            break;
          case 'bunkbed':
            icons.push( <Icon path={mdiBunkBed} size={1} key={`${facilityType}-${i}`} />);
            break;
          case 'shower':
            icons.push( <Icon path={mdiShowerHead} size={1} key={`${facilityType}-${i}`} />);
            break;
          case 'toilet':
            icons.push( <Icon path={mdiToilet} size={1} key={`${facilityType}-${i}`} />);
            break;
          case 'breakfast':
            icons.push( <Icon path={mdiCoffee} size={1} key={`${facilityType}-${i}`} />);
            break;
          case 'wifi':
            icons.push( <Icon path={mdiWifi} size={1} key={`${facilityType}-${i}`} />);
            break;
          case 'tv':
            icons.push( <Icon path={mdiTelevision} size={1} key={`${facilityType}-${i}`} />);
            break;
          default:
            break;
        }
      }
    }

    return icons;
  };

  return (
    <div>
      {generateIcons()}
    </div>
  );
};
export default BedIcons;