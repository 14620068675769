import React from 'react';
import { Box, Grid, Typography, Link } from '@mui/material';
import PhoneIcon from '@mui/icons-material/Phone';

const Footer = () => {
  return (
    <Box
      sx={{
        backgroundColor: '#099FBE', // Gray background color
        padding: 4,
        textAlign: 'center',
        alignContent: 'center',
        marginTop: 'auto', // Push the footer to the bottom of the page
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Typography variant="h6">Slite Badhotell finns i Slite på östra kusten av Gotland</Typography>
          <Typography variant="body1"><b>Adress:</b></Typography>
          <Typography>
            Kronbrunnsgatan 2, 624 48 Slite<br />
            info@slitebadhotell.se
          </Typography>
          <Typography>
            <Link href="tel:+46704433299" color="inherit">
              <PhoneIcon sx={{ marginRight: 1 }} />
              +46 70 44 33 2 99
            </Link>
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="body1"><b>Vägbeskrivningar:</b></Typography>
          <Typography>35 minuter med bil eller taxi från Visby hamn/flygplats på väg 147</Typography>
          <Typography>40 minuter med busslinje 20, 22 och 61 från Visby busstation</Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Footer;