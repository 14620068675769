import { Typography } from '@mui/material';
import React, { useEffect } from 'react';

const SirvoyScript = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.async = true;
    script.setAttribute('data-form-id', '5c308415eb5ce');
    script.src = 'https://secured.sirvoy.com/widget/sirvoy.js';

    const container = document.getElementById('sirvoy-script-container');

    if (container) {
      container.appendChild(script);
    }

    return () => {
      // Cleanup script when component is unmounted
      script.remove();
    };
  }, []);

  return <div id="sirvoy-script-container"><Typography align='center' variant='h2' color={'#5DB3E6'}>BOKA</Typography></div>;
};

export default SirvoyScript;
