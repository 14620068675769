import React, { useState } from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Typography, Container } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FaqAnswers from './GenerateFaq';

const FAQPage = () => {
  const [expanded, setExpanded] = useState(null);

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : null);
  };

  const faqData = [
    {
      question: 'Check In / Out',
      answer: {
        'Check in': 'Du är välkommen att checka in mellan klockan 15 och 20 då vi har personal på plats. Vid senare incheckning kan vi lägga ut en nyckel och om du behöver det så kontakta oss innan kl 20. Om du bokar rum med kort varsel är det bra om du ringer så vi säkerställer att du kan checka in.',
        'Check out': 'Check out är senast klockan 10. Om du vill ha senare checkout är det ibland möjligt, dock mot avgift.',
        'Notera':'Notera att det tar ca 40 minuter från hotellet till färjeterminalen i Visby.'
      }
    },
    {
      question: 'Frukost',
      answer: {
        'Högsäsong':'Vår omtyckta frukostbuffé serveras kl 7,30-9.30. Självklart går det på högsäsong att besöka vår frukost även om du inte bor på hotellet, priset är då 140 kr/person. 80 kr/barn Ring 070-44 33 299 för att boka.',
        'Lågsäsong':'När vi har färre än 4 rum uthyrda finns vår frukost till självservering, där du tar för dig själv när det passar dig.',
        'Allergier':'Om du har några allergier meddela oss detta när du checkar in eller i förvag med ett e-mail into@slitebadhotell.se',
      },
    },
    {
      question: 'Rumsfaciliteter',
      answer: {
        'Rumsfaciliteter rum med privata badrum':'De är utrustade med linne, handdukar, TV, Wifi, vattenglas, läslampor, spegel, sängbord, galgar, rullgardiner, tvål, schampo, duschkräm, spegel, papperskorgar & hårfön.',
        'Rumsfaciliteter rum med externa badrum':'De har samma utrustning som ovan förutom en TV. Toaletten och duschen ligger på en annan våning och inte i direkt anslutning till rummet.',
        'Kaffe och te': 'Kaffe och te finns att tillgå i baren på bottenvåningen och är gratis för våra hotellgäster.',
      },
    },
    {
      question: 'Städning',
      answer: {
        'Städning under vistelse': 'Vi går ej in i ditt rum efter att du checkat in. Om du har behov av något så säg till i receptionen på morgonen.',
        'Bokningar över 7 dagar': 'Vi byter linne och städar rummet var 7 dag och söker ditt godkännande innan vi gör det.',
    
      },
    },
    {
      question: 'Avbokningsregler',
      answer: {
        'Avbokning':'Gratis avbokning fram till tre dagar före ankomst när du bokar direkt hos oss här på webbsidan. Vi direktbokning hos oss får du de bästa avbokningsreglerna. Om du bokar genom andra kanaler kontrollera din bokningsbekräftelse för de val om avbokning du gjort. Vid senare avbokning debiteras bokningen 100%.',
      },
    },
    {
      question: 'Stranden',
      answer: {
        '' : 'Stranden är Slites stolthet och ligger 5 min promenad från hotellet. Utmärkt för ett morgondopp eller varför inte ett kvällsdopp efter middagen. Det är en fin långgrund strand mycket lämplig också för barn. Man kan ligga i solen eller under träden i badhusparken om man vill ha skugga. Vid stranden finns toaletter, badflotte, handikappramp, grillplats. Det finns tennisbanor, paddelbanor, pingisbord och vattensportsutrustning och kajaker att hyra.',
      },
    },
    {
      question: 'Hotellfaciliteter',
      answer: {
        'Uteplats':'Många älskar vår uteplats på baksidan av hotellet som är supermysig och många äter frukost där och hänger på kvällen när vädret är bra.',
        'Rökare':'Inne på hotellet är det rökförbud. På bakgården finns ett område där man kan sitta och röka.', 
        'Grillplats':'Grillplats finns både vid stranden och ute på det vackra Lillungsrev. Där finns både offentliga grillar och sittplatser. När det är tort i markerna kan det vara grillförbud så kontrollera i receptionen ifall du är osäker.',
        'Strykjärn':'Strykjärn och strykbräda finns att låna.',
        'Parkering':'Parkering är gratis för hotellgäster och sker framför hotellet eller i gränden på vänster sida av hotellets huvudbyggnad.',
        'Wifi':'Graits Wifi finns i hela hotellet.',
        'Babysäng':'Babysäng finns att hyra.',
        'Extrasäng':'Extrasäng får plats i några få av våra rum men det blir trångt. Går bara att boka direkt hos oss via telefon och avgiften täcker extrasängen, linne och frukost.',
        'Microvågsugn':'Det finns tillgång till microvågsugn i restaurangen',
        'Cykelgarage':'Vi har ett cykelgarage där du kan låsa in din cykel.',
        'Restaurang och Bar':'Det finns Restaurang och Bar på hotellet. Öppettiderna varierar beroende på säsong. Om hotellets restaurang är stängd finns minst 3 andra restauranger i Slite året runt och upp till 8 på högsäsong. På lågsäsong rekommenderar vi våra gäster att gå och äta middag före 19' ,
        'Cafe':'Röda Korset bedriver ett kafé på dagtid och vardagarna. På sommaren finns även kafé i trädgården till Bonden & Bönorna.' , 
        'Ingång och rum utan att gå i trappor':'Ingång utan att gå i trappor finns via trädgården på baksidan av hotellet som man når på höger sida av huvudbyggnaden. Vi har tyvärr inte hiss. Vi har en Svit som har privat ingång från trädgården utan några trappor. Vi har också ett kompakt dubbelrum på bottenvångingen med privat badrum där man ej behöver gå i trappor. I vårt annex har vi till sommaren 2024 två tillgänglihetsanpassade rum.',
        'Spel':'Spel - Bouleklot för den fina banan i parken samt det gotländska kubbspelet finns att låna i receptionen. Vi har också brädspel och barnböcker till låns.',
        'Leklplatser':'Skatboard bana finns på Torget vid hotellet, riktigt bra lekplats för barn finns på Solklintskolan',
        'Tennis, paddel och pingis':'Vid stranden i badhusparken ligger 4 grusbanor för tennis, 2 paddelbanor och ett pingisbord. De kan bokas hos Visby Tennisklubb. Racketar och bollar ordnar du själv',
      },
    },
    {
      question: 'Hundar & allergier',
      answer: {
        'Vi välkomnar hundar på hotellet':'Vi välkomnar hundar till vissa av våra rum. För att säkerställa att du får ett rum där du kan ta med hunden kontakt oss. Vi tar ut en extra städavgift per vistelse. För att det skall bli en trevlig vistelse så får de dock ej störa andra hotellgäster med tex skällande så vi ser ej att du lämnar din hund på rummet om du lämnar hotellet. Hunden får ej vistas i sängarna pga av att vi hyr linnet. Hunden kan sitta med dig i trädgården när du äter frukost om du vill. Det finns väldigt trevliga promenadstråk nära hotellet.',
        'Om du är allergisk':'Om du är allergisk så har vi ett antal allergifria rum. För att boka dessa ring oss och boka direkt. För matallergier meddela oss vad du är allergisk mot så löser vi det för frukosten.',
        'Om du inte gillar hundar':'Om du inte gillar hundar så låt oss veta så gör vi vårt bästa för att hålla dem på avstånd från dig. Du skall kunna trivas hos oss både om du gillar djur och inte.',
      },
    },
    {
      question: 'Fakturering/Voucher',
      answer: {
        'Fakturering':'Fakturering går bra om du bokar direkt hos oss och ej via någon bokningskanal som booking.com eller liknande. Vi fakturera med 7 dagar betalningsvillkor och normalt skall fakturan vara betald innan incheckning. Vi tar ej någon fakturaavgift för fakturor via email. Vid fakturering via post tillkommer en faktureringsavgift med 50 kr. Vi kan ta emot internationella betalningar också.',
        'För fakturering behöver vi':'Företagsnamn, organisationsnummer, adress, emailadress & telefonnummer.',
        'Vouchers från resebyråer':'Vouchers från resebyråer går bra. Vi är dock ej kopplade mot något voucher system så skicka dem bara till oss elektroniskt till info@slitebaden.com',
      },
    },
    {
      question: 'Lojalitetsprogram',
      answer: {
        'Återkommande gäster':'För återkommande gäster har vi ett lojalitetsprogram och då får du rabatt på din bokning. Ring oss i så fall direkt.',
      },
    },
    {
    question: 'Utflykter',
    answer: {
      'Utflykter i Slite' : 'Här rekommendear vi starkt en guidad tur till fortifkationsön Enholmen när de turerna går, på sommaren finns en väldigt informativ fabriksguidning på Cementfabriken, en promenad till Lillungsrev, en promenad förbi Sju Strömmar och Bogeviken på den nerlagda järnvägsvallen, en biltur till Asunden med de vackra Raukarna "Draken Katla", "Spikklubban", "Dromedaren" och "Klätterapan" och med ett stopp i Barlast där man kan se Kalkugnarnas utveckling över århundradena, på vägen tillbaka tar man en kort avstickare till Vikingarnas festsal med alla ekar och hassell i Dagghagens naturreservat.', 
      'Utflykter söderut efter östra kusten':'Här rekommenderar vi besök på skeppsättningen och graven för Gotlands upptäckare Tjelvar, Strumpfabriken där ull omvandlas till vackara kläder, Bronsåldersgravaran i Trullhalsar, Fornstugorna i Norrlanda, lunch på fiskrökeriet i Katthammarsvik, Magiska Konungens Sal i Mullvads Strandskog, Största fornborgen Torsbrugen och om du orkar hela vägen till vackra Närs udde med fyren',
      'Utflykter norrut' : 'Här rekommenderar vi besök Hångers Källa där Den blomstertid nu kommer diktades som i sin tur ligger ett stenkast från Gann Ödekyrka, sedan en tur till Bläse med en tågtur för barnen och klättring upp på slagghögarna med alla budskap till himlen, Stenkusten med den röda pipörten och alla klapperstenar, Blå lagunen med ett dopp i ett kalkbrott fast egentligen gillar vi industriella Smöjens kalkbrott bättre för ett bad', 
       },
    },
    // Add more FAQ entries as needed
  ];

  return (
    <Container>
    <Typography align='center' variant='h2' color={'#5DB3E6'}>FAQ</Typography>
      {faqData.map((faq, index) => (
        <Accordion
          key={index}
          expanded={expanded === `panel${index}`}
          onChange={handleAccordionChange(`panel${index}`)}
          style={{backgroundColor: '#5DB3E6'}}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography style={{fontWeight: 'bold'}}>{faq.question}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <FaqAnswers answers={faq.answer}/>            
          </AccordionDetails>
        </Accordion>
      ))}
    </Container>
  );
};

export default FAQPage;
